import React from 'react'

const index = () => {
  return (
    <div>
      <p>
        CREDIT REPORT: The Applicant, including any co-applicant/spouse who has also applied for credit and signed below 
        (together, the "Applicant") hereby expressly consents to Parkland Corporation together with its affiliates, successors and 
        assigns, (the "Supplier") obtaining a third party credit report on the Applicant. The Applicant acknowledges that the standing of 
        the Applicant’s account with the Supplier may be reported to a credit bureau from time to time.
        ACCURACY OF INFORMATION: The Applicant represents and warrants to the Supplier that all of the information contained 
        on page one of this Agreement is complete and correct and agrees to advise the Supplier immediately of any change to any of 
        such information.
      </p>
      <p>
        PRODUCTS: The Applicant agrees to purchase and receive from the Supplier, and the Supplier agrees to sell and deliver to the 
        Applicant, subject to satisfactory credit approval and the terms and conditions contained herein, residential fuel and petroleum 
        products and related services (as described on page one of this Agreement) (collectively, the "Petroleum Products") at the 
        delivery address indicated on page one of this Agreement. The Applicant agrees to i) use the Petroleum Products in a safe manner 
        and in accordance with all applicable laws and any information provided by the Supplier and ii) to pay the Supplier for the 
        Petroleum Products in accordance with the credit terms established by the Supplier based on a review of the application 
        contained in this Agreement.
      </p>
      <p>
        NO OBLIGATION: The Applicant hereby agrees that nothing herein shall bind or compel the Supplier to advance or readvance credit to the Applicant. The Supplier shall determine, in its sole discretion based on a review of the application contained 
        in this Agreement, whether to grant credit to the Applicant and on what terms. The Applicant shall be responsible for all credit it 
        receives from the Supplier whether or not such credit exceeds authorized credit limits or payment terms established by the 
        Supplier.
      </p>
      <p>
        EXCLUSIVITY: If 1) the Applicant is not a “Will-Call” customer as defined below, or 2) if the Supplier is supplying propane to 
        a “Will-Call” customer, or 3) if the Applicant is leasing equipment from the Supplier, then the Applicant shall purchase its 
        Petroleum Products exclusively from the Supplier during the term of this Agreement.
        TERM: Subject to the early termination rights set out herein, this Agreement shall commence on the date written on page one of 
        this Agreement and shall continue, thereafter until terminated by either party hereto on thirty (30) calendar days prior written 
        notice.
      </p>
      <p>
      PRICE: The Applicant shall pay to the Supplier the fixed price (if applicable) or the posted price for the Petroleum Products as 
      determined by the Supplier current at the time and place of delivery of such Petroleum Products. The Applicant may contact its 
      local Supplier branch for more information regarding pricing of Petroleum Products.
      OWNERSHIP OF PROPERTY: The Applicant shall promptly notify the Supplier, in writing, of any changes in ownership or 
      tenancy of the property located at the delivery address indicated on page one of this Agreement and failing such notification, the 
      Applicant shall be responsible for payment for all Petroleum Products deliveries made to the property.
      </p>
      <p>
        PAYMENT:
        a. Unless the Supplier otherwise notifies the Applicant in writing, or the Supplier accepts a request made by the Applicant 
        in writing for an alternate method of payment, the Supplier will establish credit terms based on a review of the 
        application contained in this Agreement.
        b. The Supplier will provide the Applicant with a statement of account each month. The Applicant is required to pay the 
        entire outstanding balance of each statement of account in full on the due date as set forth in the statement of account. 
        The due date will be thirty (30) days from the date of statement. Applicant accounts that are past the due date will 
        accrue interest at a compounded rate of 2% per month (i.e. 26.82% effective interest rate per annum) on such 
        outstanding balance from the date that such amounts become due until the date that such amounts are paid in full. The 
        Supplier may accept late payments, partial payments, or any payment marked as being payment in full or as being 
        settlement of any dispute, without losing any of its contractual or legal rights. The Supplier’s acceptance of such 
        payment does not mean it accepts to change this Agreement in any way.
        c. If the Applicant’s account becomes overdue or the Applicant otherwise defaults under the terms of this Agreement, the 
        Supplier may: (i) suspend the credit privileges extended hereunder and/or deliveries of Petroleum Products; and (ii) 
        terminate this Agreement without notice and, in each case, the Applicant shall have no claim against the Supplier for 
        any reason and the Supplier shall not be deemed in default of its obligations under this Agreement. In the event of a 
        termination of the account, the Applicant also agrees to permit the Supplier access to and egress from the Applicant’s 
        property for the purposes of removing any equipment owned by the Supplier, and the Applicant agrees that they shall 
        be responsible for all equipment removal charges in effect at the time of such removal.
        d. To the extent permitted by applicable law, a fee of a minimum of $75 shall be assessed by the Supplier against the 
        Applicant, per occurrence, for any payment made by the Applicant and returned by a bank for insufficient funds or that 
        is dishonoured or declined by the Applicant’s bank.
        e. The Applicant agrees to pay all costs and expenses incurred by Supplier in the collection of any past due account in 
        connection with this Agreement, including legal fees on a solicitor-and-client basis. The minimum fee for such 
        collection shall be deemed to be 25% of the unpaid balance. The Supplier retains title and is granted a purchase money 
        security interest for security purposes only, to all goods until paid in full by the Applicant, and the Supplier may, at its 
        option, repossess the same in the event the Applicant defaults in payment hereunder and charge the Applicant for any 
        deficiency.
        f. If an error is discovered in an invoice delivered by the Supplier to the Applicant for the delivery of Petroleum Products 
        then the Supplier will issue a corrected invoice and: (i) in the event that the amounts due under the corrected invoice 
        exceed the amounts paid by the Applicant, the Applicant shall promptly pay the difference to the Supplier; or (ii) in the 
        event that the amounts paid by the Applicant exceed the amounts due under the corrected invoice, the Supplier shall 
        credit such overpaid amount to the Applicant on the next invoice for Petroleum Products issued by the Supplier.
        DELIVERY:
        a. The Applicant hereby authorizes the Supplier to enter onto the property at the delivery address indicated on page one of 
        this Agreement for the purpose of inspecting the premises and/or delivering Petroleum Products or repairing or 
        replacing any Supplier-owned equipment pursuant to this Agreement.
        b. All new delivery locations shall be inspected by a representative of the Supplier prior to the first delivery of the 
        Petroleum Products to such new delivery location. THE SUPPLIER RESERVES THE RIGHT TO DECLINE 
        DELIVERY OF PETROLEUM PRODUCTS TO A LOCATION THAT DOES NOT, IN THE SOLE DISCRETION 
        OF THE SUPPLIER’S REPRESENTATIVE, CONTAIN A PETROLEUM PRODUCTS STORAGE SYSTEM THAT 
        MEETS OR EXCEEDS ALL CURRENTLY APPLICABLE INDUSTRY STANDARDS.
        c. Subject to the terms hereof, the Supplier shall deliver the Petroleum Products to the Applicant on: (i) an automatic 
        delivery basis; or (ii) a “customer-will-call” basis, as indicated by the Applicant on page one of this Agreement. Title 
        to, property and ownership in, and risk of loss with respect to the Petroleum Products shall pass to the Applicant as the 
        Petroleum Products pass the unloading fitting of the Supplier’s delivery mechanism.
        i. Automatic Delivery:If Automatic Delivery is available in the Applicant’s area and the Applicant chooses 
        automatic delivery, the Supplier shall fill the Applicant's Petroleum Products tank on a regular, scheduled 
        basis, without prior request by the Applicant. The Supplier will schedule automatic delivery on the basis of 
        outside temperature and annual Petroleum Products consumption, the latter of which shall be determined by 
        the Supplier based on the information provided by the Applicant on page one of this Agreement. The 
        Applicant must maintain a consistent Petroleum Product consumption pattern for the Supplier to effectively 
        schedule automatic delivery. The Applicant acknowledges that the scheduling of automatic delivery is not a 
        guarantee of delivery of Petroleum Products. The Applicant acknowledges that numerous variables beyond 
        the Supplier's control impact the consumption of Petroleum Products by the Applicant, including but not 
        limited to, inaccurate annual Petroleum Products consumption information contained on page one of this 
        Agreement, heating equipment or storage tank changes, malfunctioning equipment, property renovations, 
        short or long term lifestyle changes, or use of any additional or alternate energy heat source other than the 
        Petroleum Products.
        ii. Customer-Will-Call:If the Applicant chooses customer-will-call, the Supplier shall fill the Applicant's 
        Petroleum Products tank upon Applicant's request, provided that such request is made in accordance with the 
        Supplier’s minimum notice requirements which are available from the Supplier. In the event that the 
        Applicant requires: (i) delivery of Petroleum Products outside of regular working hours, including weekends 
        and statutory holidays; (ii) delivery of Petroleum Products upon less than the Supplier’s minimum notice 
        requirements; or (iii) delivery of Petroleum Products in less than the specified minimum delivery volume as 
        determined by the Supplier from time to time, an additional delivery charge, as determined by the Supplier, 
        may be charged by the Supplier. Such delivery charge and minimum volume will be subject to change from 
        time to time without notice.
      </p>
      <p>
        LIABILITY: TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE SUPPLIER, ITS 
        ASSOCIATES, ITS AFFILIATES, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, 
        SERVANTS, AGENTS, SUCCESSORS AND ASSIGNS BE LIABLE FOR: (I) ANY INCIDENTAL, INDIRECT, PUNITIVE, 
        EXEMPLARY, CONSEQUENTIAL OR OTHER SIMILAR DAMAGES WHATSOEVER, (INCLUDING ANY DAMAGES 
        FOR LOSS OF PROFITS, AND LOSS OF BUSINESS OPPORTUNITY) IN CONNECTION WITH ANY CLAIM, LOSS, 
        DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THIS AGREEMENT EVEN IF 
        ADVISED OF THEIR POSSIBLE EXISTENCE; OR (II) ANY FAILURE TO DELIVER THE PETROLEUM PRODUCTS 
        HEREUNDER DUE TO ANY CAUSE BEYOND ITS CONTROL, INCLUDING BUT NOT LIMITED TO AN ACT OF GOD, 
        STRIKE, FLOOD, FIRE, EMBARGO, EARTHQUAKE, INSURRECTION, UNAVOIDABLE ACCIDENT, SHORTAGE OF 
        LABOR OR PRODUCT SUPPLY OR MATERIAL, GOVERNMENT DECREE, OR WHENEVER THE AGREEMENT 
        BECOMES IMPOSSIBLE TO PERFORM, INCLUDING SHORTAGES OF PETROLEUM PRODUCTS FROM THE 
        SUPPLIER’S SUPPLIERS, OR DUE TO EXTREME WEATHER CONDITIONS AND ROAD CLOSURES AND IN THE 
        CASE OF THE APPLICANT OPTING FOR AUTOMATIC DELIVERY, INCONSISTENT PETROLEUM PRODUCTS 
        CONSUMPTION PATTERNS FOR WHATEVER REASON. IN THE EVENT OF A PETROLEUM PRODUCTS 
        SHORTAGE, THE SUPPLIER RESERVES THE RIGHT TO ALLOCATE SUPPLY AS IT SEES FIT AMONG THE 
        APPLICANT, ITS OTHER CUSTOMERS, ITSELF, ITS AFFILIATES AND ITS AUTHORIZED AGENTS. WITHOUT 
        LIMITING THE GENERALITY OF THE FOREGOING, THE SUPPLIER ALSO EXPRESSLY NEGATES AND DISCLAIMS 
        AND SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY OR IN CONNECTION WITH THE 
        FOLLOWING:
      </p>
      <p>
        a. EQUIPMENT:FOR CLARITY, UNLESS THE APPLICANT IS LEASING ITS HEATING SYSTEM AND/OR 
        RELATED EQUIPMENT, SUCH AS ITS PETROLEUM PRODUCTS STORAGE TANK, FROM THE SUPPLIER 
        (IN WHICH CASE THE TERMS OF THE RENTAL/LEASE AGREEMENT WILL PREVAIL) THE SUPPLIER 
        SHALL NOT BE LIABLE FOR ANY MALFUNCTIONS OF THE APPLICANT'S HEATING SYSTEM AND/OR 
        RELATED EQUIPMENT OR INJURY OR DAMAGE TO ANY PERSON OR PROPERTY CAUSED BY ANY 
        PETROLEUM PRODUCTS EQUIPMENT INSTALLED AT APPLICANT'S PREMISES, OR FROM ANY 
        CHANGES IN THE APPLICANT'S PETROLEUM PRODUCTS EQUIPMENT, INCLUDING ANY PETROLEUM 
        PRODUCTS STORAGE TANKS. FURTHER, THE APPLICANT ACCEPTS ALL RESPONSIBILITY AND 
        LIABILITY FOR THE CONDITION OF ANY PETROLEUM PRODUCTS STORAGE TANK AND ASSOCIATED 
        PIPING AND MAINTENANCE THEREOF, AND FOR ANY LOSS OR DAMAGE RESULTING FROM 
        LEAKAGE OR FAILURE OF SAID TANK OR PIPING AT THE APPLICANT'S PREMISES UNLESS SAID 
        STORAGE TANK AND ASSOCIATED EQUIPMENT IS LEASED/RENTED FROM THE SUPPLIER. ANY 
        DAMAGE TO PROPERTY OR GOODS STORED IN THE VICINITY OF A PETROLEUM PRODUCTS 
        STORAGE TANK, BOILER OR FURNACE WILL NOT BE THE RESPONSIBILITY OF THE SUPPLIER;
        b. PETROLEUM PRODUCTS RUN-OUT:FOR CLARITY, THE SUPPLIER SHALL NOT BE LIABLE IF THE 
        APPLICANT HAS REQUESTED AUTOMATIC DELIVERY AND FAILS TO NOTIFY THE SUPPLIER 
        IMMEDIATELY OF ANY CHANGES THAT COULD IMPACT THE APPLICANT'S PETROLEUM PRODUCTS 
        CONSUMPTION, INCLUDING BUT NOT LIMITED TO, HEATING EQUIPMENT OR STORAGE TANK 
        CHANGES, MALFUNCTIONING EQUIPMENT, PROPERTY RENOVATIONS, SHORT OR LONG TERM 
        LIFESTYLE CHANGES OR USE OF ANY ADDITIONAL OR ALTERNATE ENERGY SOURCE OTHER THAN 
        THE PETROLEUM PRODUCTS;
        c. PREMISES:FOR CLARITY, THE SUPPLIER SHALL NOT BE LIABLE FOR INJURY OR DAMAGE TO ANY 
        PERSON OR PROPERTY CAUSED BY A LACK OF HEAT, INCLUDING TO WATER PIPES, STORAGE 
        TANKS AND/OR ASSOCIATED PIPING, REGARDLESS OF DELIVERY STATUS. THE APPLICANT IS 
        RESPONSIBLE FOR ENSURING THAT THE APPLICANT’S HEATING SYSTEM IS CHECKED DAILY FOR 
        PROPER OPERATION AND SHALL ENSURE THE PROPERTY IS NOT LEFT VACANT OR UNOCCUPIED
        FOR MORE THAN TWENTY FOUR (24) HOURS. THE APPLICANT SHALL BE LIABLE FOR ANY COSTS OR 
        DAMAGES TO ITSELF OR TO THE SUPPLIER DUE TO THE APPLICANT’S FAILURE TO KEEP ACCESS TO 
        THE PETROLEUM PRODUCTS FILL PIPE EASY AND SAFE FOR THE SUPPLIER’S DELIVERY 
        REPRESENTATIVES, AND SUPPLIER WILL ALSO NOT BE LIABLE FOR DAMAGE TO APPLICANT’S 
        DRIVEWAY OR PROPERTY UNLESS SUCH DAMAGE IS DUE TO SUPPLIER’S GROSS NEGLIGENCE; OR,
        d. PETROLEUM PRODUCTS STORAGE:FOR CLARITY, THE SUPPLIER IS NOT LIABLE FOR CHANGES TO 
        PETROLEUM PRODUCTS RESULTING FROM OUTDOOR STORAGE IN COLD TEMPERATURES BY THE 
        APPLICANT.
      </p>
      <p>
        NOTICE: Correspondence to the Supplier shall be made to: Parkland Credit Department, 1800, 240-4 Ave SW, Calgary, 
        Alberta, T2P 4H4 Attention: Residential Credit Representative. The Supplier may provide notices to the Applicant by phone, fax, 
        email or mail to the Applicant’s telephone or fax numbers, email or address as set out on the first page of this Agreement. In all 
        cases, written notice will be deemed to be delivered by each party hereto as of the postmark date of mailing or as of the date and 
        time attached to an electronic mail notice that is not automatically returned as undeliverable.
        AMENDMENTS: The Supplier reserves the right to amend the terms and conditions of this Agreement from time to time in its 
        sole discretion.
      </p>
      <p>
        ASSIGNMENT: The Supplier reserves the right to assign this Agreement without the prior consent of Applicant and without 
        providing prior notice to the Applicant of such intent to assign. This Agreement is not assignable by the Applicant without the 
        express written permission of the Supplier.
      </p>
      <p>
        SUCCESSORS AND ASSIGNS: This Agreement shall be binding upon and shall enure to the benefit of the parties hereto, their 
        heirs, executors, administrators, successors and permitted assigns.
        SURVIVAL: All representations and warranties and all indemnity provisions contained in this Agreement shall survive the 
        termination of this Agreement.
      </p>
      <p>
        GOVERNING LAW: This Agreement shall be governed by, and construed and enforced in accordance with the laws applicable 
        in the jurisdiction in which the Petroleum Products are delivered, and the laws of Canada applicable therein. The parties hereto 
        irrevocably submit and attorn to the exclusive jurisdiction of the courts of the jurisdiction in which the Petroleum Products are 
        delivered for all matters arising out of or relating to this Agreement, or any of the transactions contemplated hereby.
        JUDGEMENT: To the extent permitted by applicable law, the taking of a judgement or judgements for payment of the account 
        or performance of the obligations herein shall not operate as a merger or affect the right to service charges at the rate and times 
        aforesaid on any monies owing, and that such judgments shall provide that interest thereon shall be calculated at the same rate 
        and in the same manner as hereto provided until the said judgment has been fully paid and satisfied.
        WAIVER: No failure or delay of the Supplier to exercise any right given to it hereunder, or to insist on strict compliance with 
        any provision hereunder, shall constitute a waiver of such provision or of any other provision hereof, or a waiver of any breach, 
        and no waiver of any provision or breach of any provision shall constitute a waiver of any other provision or breach or of any 
        subsequent breach of the same provision. No waiver shall be effective unless in writing and signed by the Supplier.
        MAXIMUM INTEREST: No interest or fee to be paid hereunder shall be paid at a rate exceeding the maximum rate permitted 
        by applicable law. In the event any such interest or fee exceeds such maximum rate, such interest or fee shall be reduced or 
        refunded, as the case may be, so as to be payable at the highest rate recoverable under applicable law.
        SEVERABILITY: Any provision in this Agreement that is prohibited or unenforceable in any jurisdiction shall, as to such 
        jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions 
        hereof or affecting the validity or enforceability of such provisions in any other jurisdiction.
        EQUALIZED PAYMENT PLAN: Where available, the Applicant may elect to pay for its estimated annual requirements in 
        equal monthly installments or other preapproved monthly installments, subject to the account being paid in full thirty (30) days 
        after processing the last installment of any given twelve (12) month period. The Applicant's equalized payment plan will 
        normally renew each year after the account has been paid in full within thirty (30) days of the last installment of each twelve (12) 
        month period, unless special payment arrangements have been made with the Supplier’s credit department. The equalized 
        payment balance will be used to determine the equalized payments for the following twelve (12) month period and, if 
        adjustments need to be made, the Supplier will either increase or decrease the payment amount for the following twelve (12) 
        month period upon notice to the Applicant. A letter from the Supplier to the Applicant specifying the new equalized payment 
        amount to be paid will be forwarded by mail. THIS LETTER ONLY CONSTITUTES AN ESTIMATE AND THE APPLICANT 
        REMAINS SOLELY RESPONSIBLE (i) FOR REVIEWING THE SCHEDULED PAYMENTS IN VIEW OF ITS ACTUAL 
        CONSUMPTION AND (ii) FOR THE PAYMENT OF THE OUTSTANDING YEARLY BALANCE.
        LANGUAGE: The parties hereto confirm that it is their express intent that this Agreement be drawn up in English only. Les 
        parties aux présentes confirment leur volonté expresse que la présente convention soit rédigée en anglais seulement.
      </p>
    
  </div>
  )
}

export default index